import { numAutoValidator } from '@/core'

export const ruleFormAddTimeslot = {
  numAuto: [
    {
      required: true,
      message: 'Заполните номер авто',
      trigger: 'blur',
    },
    {
      required: true,
      validator: (rule, value, callback) => {
        numAutoValidator(value) ? callback(new Error()) : callback()
      },
      message:
        'Формат номера должен быть "м213тт99" или "1212ттт" или "e2233кк"',
      trigger: 'blur',
    },
  ],
}
