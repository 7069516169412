var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Dialog",
    {
      staticClass: "add-timeslot",
      attrs: { name: _vm.name, width: "475px" },
      on: { close: _vm.handleCloseWithoutSubmit },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function () {
            return [
              _c(
                "div",
                { staticStyle: { "font-size": "16px", "font-weight": "400" } },
                [_vm._v("Добавление авто")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "formAddTimeslot",
              staticClass: "form-add-timeslot",
              staticStyle: { "flex-direction": "column" },
              attrs: { model: _vm.formAddTimeslot, rules: _vm.rules },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "df",
                  staticStyle: { "flex-direction": "column" },
                },
                [
                  _c("div", { staticClass: "m-b-5" }, [_vm._v("Номер авто")]),
                  _c("IqInputSearchForm", {
                    attrs: {
                      "input-width": "100%",
                      prop: "numAuto",
                      rules: _vm.rules.numAuto,
                      disabled: _vm.isLoading,
                      loading: _vm.isLoading,
                    },
                    on: { search: _vm.handleCloseDialog },
                    nativeOn: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.handleCloseDialog.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.formAddTimeslot.numAuto,
                      callback: function ($$v) {
                        _vm.$set(_vm.formAddTimeslot, "numAuto", $$v)
                      },
                      expression: "formAddTimeslot.numAuto",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }